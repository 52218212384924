<template>
  <div class="w-sm-100 w-xs-100">
    <div class="md-trading-content">

      <vs-row v-if="showProfileReminder">
        <div class="trading-profile-reminder text-medium p-1 mb-2">
          Please complete your <a @click="goToProfilePage()" class="pointer">profile</a> before adding your bank account
        </div>

      </vs-row>
      <h4 class="p-1">Add bank account</h4>
      <div class="p-1 text-medium">Fill out your bank account details. Withdrawals can only be made to your bank account. </div>

      <vs-row class="p-1">
        <label class="control-label">
          Bank Account Holder Name
        </label>
        <div class="input-group">
          <input type="text"
                 class="form-control"
                 v-model="selfBankAccountHolderName"
          >
        </div>
        <small v-if="validationSelfBankAccountHolderNameError" class="text-error text-small">Please enter
          the bank account
          holder name</small>
      </vs-row>
      <vs-row class="p-1">
        <label class="control-label">
          Bank Name
        </label>
        <div class="input-group">
          <input type="text"
                 class="form-control"
                 v-model="selfBankName"
          >
        </div>
        <small v-if="validationSelfBankNameError" class="text-error text-small">Please enter your bank
          name</small>
      </vs-row>
      <vs-row class="mb-2">
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Bank Account Number
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="selfBankAccountNumber"
              >
            </div>
            <small v-if="validationSelfBankAccountNumberError" class="text-error text-small">Please enter your
              bank account
              number</small>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Bank Account Currency
            </label>
            <div class="input-group">
              <v-select :options="receiveCurrencyList"
                        :clearable="false"
                        label="currencyCode"
                        id="bank-account-currency-myself"
                        name="bank-account-currency-myself"
                        class="w-100"
                        v-model="selfBankAccountCurrency"
                        :reduce="item => item.currencyCode"
                        style="background-color: #FFFFFF"
              disabled>
                <template v-slot:option="item">
                  {{ item.currencyCode }} ({{ item.currencyName }})
                </template>
              </v-select>
            </div>
            <small v-if="validationSelfBankAccountCurrencyError" class="text-error text-small">Please enter
              your bank account
              currency</small>
          </div>

        </vs-col>

      </vs-row>
      <vs-row class="mb-2">
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Bank Country
            </label>
            <div class="input-group">
              <v-select :options="countryList"
                        :clearable="false"
                        id="bank-country-known"
                        label="name"
                        class="w-100"
                        v-model="selfBankCountry"
                        :reduce="item => item.countryCode"
                        style="background-color: #FFFFFF"
                        disabled>
                <template v-slot:option="item">
                  <country-flag :country="item.countryCode.toLowerCase()"
                                size="small"></country-flag>
                  {{ item.name }}
                </template>
              </v-select>
            </div>
            <small v-if="validationSelfBankCountryError" class="text-error text-small">Please enter the bank
              country
            </small>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              SWIFT Code
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="selfBankSWIFTCode"
              >
            </div>
            <small v-if="validationSelfBankSWIFTCodeError" class="text-error text-small">Please enter SWIFT
              Code</small>
          </div>

        </vs-col>

      </vs-row>

      <br>
      <vs-row class="mb-2">
        <!--Button is disabled if profile has not been filled in-->
        <button class="w-100 btn-trading-positive"
                @click="addBankAccount()"
                :disabled="showProfileReminder"
                :class="{'btn-disabled' : showProfileReminder}">Add bank account</button>
      </vs-row>

      <p v-if="addBankAccountSuccessful" class="text-center text-success text-medium">Bank Account successfully added ! <a @click="goToWithdrawPage()" class="pointer">Go to Withdraw Page</a> </p>


    </div>

  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import BigNumber from 'bignumber.js';
import {SearchIcon, ChevronDownIcon} from 'vue-feather-icons'
// import { countries } from 'country-flag-icons';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CountryFlag from 'vue-country-flag'

import {debounce} from '../../helpers/helpers'
import {countries} from "countries-list";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  name: 'withdrawal-bank-account-page',
  components: {
    vSelect,
    CountryFlag
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      selfBankAccountHolderName: "",
      selfBankAccountNumber: "",
      selfBankName: "",
      selfBankSWIFTCode: "",
      selfBankCountry: "SG",
      selfBankAccountCurrency: "SGD",

      validationSelfBankAccountHolderNameError: false,
      validationSelfBankAccountNumberError: false,
      validationSelfBankNameError: false,
      validationSelfBankSWIFTCodeError: false,
      validationSelfBankCountryError: false,
      validationSelfBankAccountCurrencyError: false,

      countryList: Object.entries(countries).map((item) => {
        return Object.assign(item[1], {countryCode: item[0]})
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }).filter(item => ["SG"].indexOf(item.countryCode) >= 0),

      receiveCurrencies: [
        {countryCode: "sg", currencyCode: "SGD", currencyName: "Singapore Dollar"},
        //   {countryCode: "us", currencyCode: "USD", currencyName: "United States Dollar"},
        // {countryCode: "eu", currencyCode: "EUR", currencyName: "Euro"},
        // {countryCode: "gb", currencyCode: "GBP", currencyName: "British Pound"},
        // {countryCode: "au", currencyCode: "AUD", currencyName: "Australia Dollar"},
        //
        // {countryCode: "za", currencyCode: "ZAR", currencyName: "South Africa Rand"},
        // //receiving currencies
        // {countryCode: "ae", currencyCode: "AED", currencyName: "United Arab Emirates Dirham"},
        // {countryCode: "bh", currencyCode: "BHD", currencyName: "Bahraini Dinar"},
        // {countryCode: "bn", currencyCode: "BND", currencyName: "Bruneian Dollar"},
        // {countryCode: "ca", currencyCode: "CAD", currencyName: "Canadian Dollar"},
        // {countryCode: "ch", currencyCode: "CHF", currencyName: "Swiss Franc"},
        // {countryCode: "cn", currencyCode: "CNY", currencyName: "Chinese Yuan"},
        // {countryCode: "dk", currencyCode: "DKK", currencyName: "Danish Krone"},
        // {countryCode: "eg", currencyCode: "EGP", currencyName: "Egyptian Pound"},
        // {countryCode: "hk", currencyCode: "HKD", currencyName: "Hong Kong Dollar"},
        // {countryCode: "hu", currencyCode: "HUF", currencyName: "Hungarian Forint"},
        // {countryCode: "id", currencyCode: "IDR", currencyName: "Indonesian Rupiah"},
        // {countryCode: "in", currencyCode: "INR", currencyName: "Indian Rupee"},
        // {countryCode: "jp", currencyCode: "JPY", currencyName: "Japanese Yen"},
        // {countryCode: "kr", currencyCode: "KRW", currencyName: "South Korean Won"},
        // {countryCode: "kw", currencyCode: "KWD", currencyName: "Kuwaiti Dinar"},
        // {countryCode: "lk", currencyCode: "LKR", currencyName: "Sri Lankan Rupee"},
        // {countryCode: "mu", currencyCode: "MUR", currencyName: "Mauritian Rupee"},
        // {countryCode: "mx", currencyCode: "MXN", currencyName: "Mexican Peso"},
        // {countryCode: "my", currencyCode: "MYR", currencyName: "Malaysian Ringgit"},
        // {countryCode: "no", currencyCode: "NOK", currencyName: "Norwegian Krone"},
        // {countryCode: "nz", currencyCode: "NZD", currencyName: "New Zealand Dollar"},
        // {countryCode: "ph", currencyCode: "PHP", currencyName: "Philippine Peso"},
        // {countryCode: "pl", currencyCode: "PLN", currencyName: "Polish Zloty"},
        // {countryCode: "qa", currencyCode: "QAR", currencyName: "Qatari Riyal"},
        // {countryCode: "ru", currencyCode: "RUB", currencyName: "Russian Ruble"},
        // {countryCode: "sa", currencyCode: "SAR", currencyName: "Saudi Arabian Riyal"},
        // {countryCode: "se", currencyCode: "SEK", currencyName: "Swedish Krona"},
        // {countryCode: "th", currencyCode: "THB", currencyName: "Thai Baht"},
        // {countryCode: "tr", currencyCode: "TRY", currencyName: "Turkish Lira"},
        // {countryCode: "tw", currencyCode: "TWD", currencyName: "Taiwan New Dollar"},
        // // {countryCode: "ng", currencyCode: "NGN", currencyName: "Nigerian Naira"},
        // {countryCode: "br", currencyCode: "BRL", currencyName: "Brazilian Real"},
        // {countryCode: "bd", currencyCode: "BDT", currencyName: "Bangladesh Taka"},
        // {countryCode: "co", currencyCode: "COP", currencyName: "Colombian Peso"},
        // {countryCode: "gh", currencyCode: "GHS", currencyName: "Ghanaian Cedi"},
        // {countryCode: "ke", currencyCode: "KES", currencyName: "Kenyan Shilling"},
        // {countryCode: "pe", currencyCode: "PEN", currencyName: "Peruvian Sol"},
        // {countryCode: "tz", currencyCode: "TZS", currencyName: "Tanzanian Shilling"}
      ],
      addBankAccountSuccessful: false,

      debugConsole : false,




    }
  },
  watch: {},
  computed: {
    ...mapGetters(["userDetailsGetter"]),
    receiveCurrencyList() {
      let countries = this.receiveCurrencies;
      let sortedCountries = countries.sort((a, b) => (a.currencyCode > b.currencyCode) ? 1 : -1);
      return sortedCountries;
    },
    showProfileReminder(){
      let profile  = this.userDetailsGetter && this.userDetailsGetter.profile;

      //check if any of the profile is empty
      if(!profile.senderAddress || !profile.senderCity || !profile.senderCountry || !profile.senderDateOfBirth || !profile.senderFirstAndMiddleName || !profile.senderLastName || !profile.senderPhone
      || !profile.senderPostalCode)
      {
        return true;
      }else
      {
        return false;
      }
    }
  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
   if(this.debugConsole) console.log("Add bank account page");


  },
  methods: {
    ...mapActions(["createRecipientProfile", "updateRecipientBank", "getReceivers"]),

    addBankAccount() {

      //validation
      if (!this.selfBankAccountHolderName) {
        this.validationSelfBankAccountHolderNameError = true;
      } else {
        this.validationSelfBankAccountHolderNameError = false;
      }

      if (!this.selfBankAccountNumber) {
        this.validationSelfBankAccountNumberError = true;
      } else {
        this.validationSelfBankAccountNumberError = false;
      }

      if (!this.selfBankName) {
        this.validationSelfBankNameError = true;
      } else {
        this.validationSelfBankNameError = false;
      }

      if (!this.selfBankSWIFTCode) {
        this.validationSelfBankSWIFTCodeError = true;
      } else {
        this.validationSelfBankSWIFTCodeError = false;
      }

      if (!this.selfBankCountry) {
        this.validationSelfBankCountryError = true;
      } else {
        this.validationSelfBankCountryError = false;
      }

      if (!this.selfBankAccountCurrency) {
        this.validationSelfBankAccountCurrencyError = true;
      } else {
        this.validationSelfBankAccountCurrencyError = false;
      }

      if (this.validationSelfBankAccountHolderNameError ||
          this.validationSelfBankAccountNumberError ||
          this.validationSelfBankNameError ||
          this.validationSelfBankSWIFTCodeError || this.validationSelfBankCountryError || this.validationSelfBankAccountCurrencyError) {
        return;
      }


      //send to API to create bank account (need recipient details, which is taken from the profile page)
      let payload = {}
      payload.receiver_type = "self";
      payload.name = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderFirstAndMiddleName + " " + this.userDetailsGetter.profile.senderLastName);
      payload.country = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderCountry;
      payload.address_lines = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderAddress;
      payload.address_city = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderCity;
      payload.postal_code = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderPostalCode;
      payload.email = this.userDetailsGetter.email;

      if(this.debugConsole)  console.log("Payload for self recipient", payload);

      this.createRecipientProfile(payload).then(res => {
        if(this.debugConsole)  console.log("Create recipient profile in adding bank account", res);

        let receiverKey = res.data && res.data.receiver_key;

        //get the receiver key to update the bank
        let payload = {}
        payload.receiverKey = receiverKey;
        payload.bank = {}
        payload.bank.acct_name = this.selfBankAccountHolderName;
        payload.bank.acct_num = this.selfBankAccountNumber;
        payload.bank.bank_name = this.selfBankName;
        payload.bank.bank_swift = this.selfBankSWIFTCode;
        payload.bank.bank_country = this.selfBankCountry;
        payload.bank.acct_ccy = this.selfBankAccountCurrency;


        this.updateRecipientBank(payload).then(res => {
          if(this.debugConsole)   console.log("result in updating bank account in adding bank account page", res)

          this.addBankAccountSuccessful = true;

          //emptied the fields
          this.selfBankAccountHolderName = "";
          this.selfBankAccountNumber = "";
          this.selfBankName = "";
          this.selfBankSWIFTCode = "";
          this.selfBankCountry = "";
          this.selfBankAccountCurrency = "";

          //get the latest receivers (bank accounts) after adding the new one.
          this.getReceivers().then(res => {
            if(this.debugConsole)   console.log("Get Receivers in the adding bank account page", res);
          }).catch(err => {
            console.log("Error in getting the receivers in adding bank account page", err);
          })

        }).catch(err => {
          console.log("Error in updating the recipient bank", err);
          this.addBankAccountSuccessful = false;
        })
      }).catch(err => {
        console.log("Error in creating recipient profile", err);
        this.addBankAccountSuccessful = false;
      })


    },

    goToWithdrawPage() {
      this.$router.push('/trading/withdraw');
    },
    goToProfilePage(){
      this.$router.push('/trading/profile');
    }


  }
}

</script>
<style>


</style>